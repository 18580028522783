//GA - Simulate pageview
var gaPageView = function() {
	ga('send', 'pageview');
};

$(document).ready(function() {
	$('#pickFile').click(function(event) {
		event.preventDefault();
		$('#fileupload').click();
	});
	
	$('#fileupload').bind('fileuploadsubmit', function(e, data) {
		data.formData = {
			resize: parseInt($('#resizevalue').val(), 10)
		};

		$('#processing').css('display', 'block');

		$('#output').stop(true, true).slideUp('fast');
	}).fileupload({
		url: $('form').attr('action'),
		dataType: 'json',
		done: function(e, data) {
			if (typeof(data.result) !== 'object' || typeof(data.result.html) !== 'string')
				data.result = { html: 'Er is een onbekende fout opgetreden. Probeer het later opnieuw.'};

			$('#processing').css('display', 'none');

			$('#output').html('<div align="center">'+ data.result.html +'</div>').slideDown('slow');				

			gaPageView();
		},
		progressall: function (e, data) {
			/*var progress = parseInt(data.loaded / data.total * 100, 10);
			$('#progress .progress-bar').css(
				'width',
				progress + '%'
			);*/
		}
	});
});